<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="4">
        <BaseMonthPicker
          :value.sync="monthYear"
          :label="$t('forMonth')"
          :showMonthPicker.sync="showMonthPicker"
        />
      </v-col>
      <v-col cols="12" lg="4" align-self="end" class="d-flex justify-end">
        <v-btn
          :disabled="!limitsHaveMissingValue"
          @click="initMissingLimits"
          block
          large
          title
        >
          {{ $t('limitCreate') }}
        </v-btn>
      </v-col>
      <v-col cols="12" lg="4" align-self="end" class="d-flex justify-end">
        <v-btn
          :disabled="!limitsHaveMissingValue"
          @click="copyFromPreviousMonth"
          block
          large
          title
        >
          {{ $t('limitCopyPreviousMonth') }}</v-btn
        >
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="5">
        <span class="text-md-h5">{{ $t('category') }}</span>
      </v-col>
      <v-col cols="3">
        <span class="text-md-h5">{{ $t('value') }}</span>
      </v-col>
      <v-col cols="4" class="d-flex justify-end">
        <span class="text-md-h5">{{ limitSum | currency }}</span>
      </v-col>
    </v-row>

    <v-divider class="mb-6"></v-divider>

    <v-row
      v-for="limitForCategory in limitForCategoryData"
      :key="limitForCategory.category"
    >
      <v-col cols="5">
        {{ limitForCategory.category_name }}
      </v-col>
      <v-col cols="3">
        {{ limitForCategory.value | currency }}
      </v-col>
      <v-col cols="4" class="d-flex justify-end">
        <v-btn-toggle>
          <v-btn
            color="primary"
            :disabled="limitForCategory.value !== '-'"
            @click="createLimit(limitForCategory)"
          >
            <v-icon>{{ mdiPlus }}</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="limitForCategory.value === '-'"
            @click="editLimit(limitForCategory)"
          >
            <v-icon>{{ mdiPencil }}</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <LimitForm
      v-if="showForm"
      :showForm.sync="showForm"
      :limitData="editLimitData"
    />
  </v-container>
</template>

<script>
import { mdiPencil, mdiPlus } from '@mdi/js';
import { mapActions, mapGetters } from 'vuex';

import BaseMonthPicker from '@/components/inputs/BaseMonthPicker';

import { categoriesData } from '@/mixins/categoriesMixin';
import monthPickerMixin from '@/mixins/monthPickerMixin';

export default {
  components: {
    BaseMonthPicker,
    LimitForm: () => import('@/components/forms/LimitForm'),
  },

  mixins: [categoriesData, monthPickerMixin],

  data: () => ({
    editLimitData: {},
    mdiPencil,
    mdiPlus,
    showForm: false,
  }),

  computed: {
    ...mapGetters([
      'limitForCategoryData',
      'limitsHaveMissingValue',
      'limitSum',
    ]),
  },

  created() {
    if (!this.categoriesData.length) this.getCategoriesData();
    this.getLimits({
      date: this.expenseMonth,
    });
  },

  methods: {
    ...mapActions(['copyLimitsFromPreviousMonth', 'getLimits', 'saveLimit']),

    getDefaultLimitData(data) {
      const defaultData = { date: `${this.monthYear}-01`, value: 0 };

      return { ...data, ...defaultData };
    },

    createLimit(limitToCreate) {
      const defaultData = { date: `${this.monthYear}-01`, value: 0 };
      const limitData = this.getDefaultLimitData(limitToCreate);

      this.saveLimit({ limit: limitData });
    },

    async copyFromPreviousMonth() {
      await this.copyLimitsFromPreviousMonth({
        year: this.expenseMonth.year,
        month: this.expenseMonth.month,
      });
      await this.getLimits({
        date: this.expenseMonth,
      });
    },

    editLimit(data) {
      this.editLimitData = { ...data };
      this.showForm = true;
    },

    initMissingLimits() {
      this.limitForCategoryData.forEach((limit) => {
        if (limit.value === '-') {
          const limitData = this.getDefaultLimitData(limit);
          this.saveLimit({ limit: limitData });
        }
      });
    },
  },

  watch: {
    monthYear() {
      this.getLimits({
        date: this.expenseMonth,
      });
    },
  },
};
</script>
